import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// home
const Home = React.lazy(() => import('../pages/Home'));

// landings
const App = React.lazy(() => import('../pages/landings/App'));
const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));
const Saas2 = React.lazy(() => import('../pages/landings/Saas/Saas2'));
const Startup = React.lazy(() => import('../pages/landings/Startup'));
const Software = React.lazy(() => import('../pages/landings/Software'));

const Agency = React.lazy(() => import('../pages/landings/Agency'));
const Coworking = React.lazy(() => import('../pages/landings/Coworking'));
const Crypto = React.lazy(() => import('../pages/landings/Crypto'));
const Marketing = React.lazy(() => import('../pages/landings/Marketing'));
const Portfolio = React.lazy(() => import('../pages/landings/Portfolio'));

// pages
const Dashboard = React.lazy(() => import('../pages/other/account/Dashboard'));
const Settings = React.lazy(() => import('../pages/other/account/Settings'));

const Blog = React.lazy(() => import('../pages/other/Blog'));
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));

const Company = React.lazy(() => import('../pages/other/Company'));
const Contact = React.lazy(() => import('../pages/other/Contact'));
const Career = React.lazy(() => import('../pages/other/Career'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));

const PortfolioGrid = React.lazy(() => import('../pages/other/portfolio/PortfolioGrid'));
const PortfolioMasonry = React.lazy(() => import('../pages/other/portfolio/PortfolioMasonry'));
const PortfolioItem = React.lazy(() => import('../pages/other/portfolio/PortfolioItem'));

const HelpDesk = React.lazy(() => import('../pages/other/HelpDesk'));

// docs
const Introduction = React.lazy(() => import('../pages/docs/Introduction'));
const QuickStart = React.lazy(() => import('../pages/docs/QuickStart'));
const Customization = React.lazy(() => import('../pages/docs/Customization'));
const Routing = React.lazy(() => import('../pages/docs/Routing'));
const CodeSpliting = React.lazy(() => import('../pages/docs/CodeSpliting'));
const ChangeLog = React.lazy(() => import('../pages/docs/ChangeLog'));

const Colors = React.lazy(() => import('../pages/docs/Colors'));
const Typography = React.lazy(() => import('../pages/docs/Typography'));

const Bootstrap = React.lazy(() => import('../pages/docs/Bootstrap'));
const Custom = React.lazy(() => import('../pages/docs/Custom'));
const Plugins = React.lazy(() => import('../pages/docs/Plugins'));

const Navbars = React.lazy(() => import('../pages/docs/Navbars'));
const Heros = React.lazy(() => import('../pages/docs/Heros'));


//TRACT
const Tract = React.lazy(() => import('../pages/TRACT/Home'))
const TractSignUp = React.lazy(() => import('../pages/TRACT/Auth/tract_sign_up'))
const TractLogin = React.lazy(() => import('../pages/TRACT/Auth/tract_login'))
const TractLogout = React.lazy(() => import('../pages/TRACT/Auth/tract_logout'))
const TractConfirm = React.lazy(() => import('../pages/TRACT/Auth/tract_confirm'))
const TractForgetPassword = React.lazy(() => import('../pages/TRACT/Auth/tract_forget_password'))
const TractResetPassword = React.lazy(() => import('../pages/TRACT/Auth/tract_reset_password'))
const TractSolution = React.lazy(() => import('../pages/TRACT/Solution'))
const TractHiring = React.lazy(() => import('../pages/TRACT/Hiring'))
const TractNewsletter = React.lazy(() => import('../pages/TRACT/Newsletter'))
const TractPricing = React.lazy(() => import('../pages/TRACT/Pricing'))
const TractClientPortal = React.lazy(() => import('../pages/TRACT/Client_Portal'))
const TractReports = React.lazy(() => import('../pages/TRACT/Reports'))
const TractSubmitRequest = React.lazy(() => import('../pages/TRACT/Client_Portal'))


const TractAdminSignUp = React.lazy(() => import('../pages/TRACT/Admin/tract_admin_sign_up'))
const TractAdminLogin = React.lazy(() => import('../pages/TRACT/Admin/tract_admin_login'))
const TractAdminLogout = React.lazy(() => import('../pages/TRACT/Admin/tract_admin_logout'))
const TractAdminSetting = React.lazy(() => import('../pages/TRACT/Admin/Settings'))




const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/admin',
            children: [
                {
                    path: '',
                    element: <LoadComponent component={TractAdminLogin} />,
                },
                {
                    path: 'login',
                    element: <LoadComponent component={TractAdminLogin} />,
                },
                {
                    path: 'sign_up',
                    element: <LoadComponent component={TractAdminSignUp} />,
                },
                {
                    path: 'logout',
                    element: <LoadComponent component={TractAdminLogout} />,
                },
                {
                    path: 'setting',
                    element: <LoadComponent component={TractAdminSetting} />,
                }
            ]
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'tract',
                    element: <LoadComponent component={Tract} />,
                },
                {
                    path: 'tract_sign_up',
                    element: <LoadComponent component={TractSignUp} />,
                },
                {
                    path: 'tract_login',
                    element: <LoadComponent component={TractLogin} />,
                },
                {
                    path: 'tract_logout',
                    element: <LoadComponent component={TractLogout} />,
                },
                {
                    path: 'tract_confirm',
                    element: <LoadComponent component={TractConfirm} />,
                },
                {
                    path: 'tract_forget_password',
                    element: <LoadComponent component={TractForgetPassword} />,
                },
                {
                    path: 'tract_reset_password',
                    element: <LoadComponent component={TractResetPassword} />,
                },
                {
                    path: 'tract_solutions',
                    element: <LoadComponent component={TractSolution} />,
                },
                {
                    path: 'tract_hiring',
                    element: <LoadComponent component={TractHiring} />,
                },
                {
                    path: 'tract_newsletter',
                    element: <LoadComponent component={TractNewsletter} />,
                },
                {
                    path: 'tract_pricing',
                    element: <LoadComponent component={TractPricing} />,
                },
                {
                    path: 'tract_client_portal',
                    element: <LoadComponent component={TractClientPortal} />,
                },
                {
                    path: 'tract_submit_request',
                    element: <LoadComponent component={TractSubmitRequest} />,
                },
                {
                    path: 'tract_reports',
                    element: <LoadComponent component={TractReports} />,
                },
                // {
                //     path: 'tract_stripe',
                //     element: <LoadComponent component={TractStripe} />,
                // },                
                // {
                //     path: 'auth',
                //     children: [
                //         { path: 'login', element: <LoadComponent component={Login} /> },
                //         { path: 'signup', element: <LoadComponent component={SignUp} /> },
                //         { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                //         { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                //         { path: 'logout', element: <LoadComponent component={Logout} /> },
                //     ],
                // },
                // {
                //     path: 'docs',
                //     children: [
                //         { path: 'introduction', element: <LoadComponent component={Introduction} /> },
                //         { path: 'quick-start', element: <LoadComponent component={QuickStart} /> },
                //         { path: 'customization', element: <LoadComponent component={Customization} /> },
                //         { path: 'routing', element: <LoadComponent component={Routing} /> },
                //         { path: 'code-spliting', element: <LoadComponent component={CodeSpliting} /> },
                //         { path: 'change-log', element: <LoadComponent component={ChangeLog} /> },

                //         { path: 'colors', element: <LoadComponent component={Colors} /> },
                //         { path: 'typography', element: <LoadComponent component={Typography} /> },
                //         { path: 'bootstrap', element: <LoadComponent component={Bootstrap} /> },
                //         { path: 'custom', element: <LoadComponent component={Custom} /> },
                //         { path: 'plugins', element: <LoadComponent component={Plugins} /> },
                //         { path: 'navbars', element: <LoadComponent component={Navbars} /> },
                //         { path: 'heros', element: <LoadComponent component={Heros} /> },
                //     ],
                // },
                // {
                //     path: 'home',
                //     element: <LoadComponent component={Home} />,
                // },
                // {
                //     path: 'landing',
                //     children: [
                //         { path: 'app', element: <LoadComponent component={App} /> },
                //         { path: 'saas', element: <LoadComponent component={Saas} /> },
                //         { path: 'saas2', element: <LoadComponent component={Saas2} /> },
                //         { path: 'startup', element: <LoadComponent component={Startup} /> },
                //         { path: 'software', element: <LoadComponent component={Software} /> },

                //         { path: 'agency', element: <LoadComponent component={Agency} /> },
                //         { path: 'coworking', element: <LoadComponent component={Coworking} /> },
                //         { path: 'crypto', element: <LoadComponent component={Crypto} /> },
                //         { path: 'marketing', element: <LoadComponent component={Marketing} /> },
                //         { path: 'portfolio', element: <LoadComponent component={Portfolio} /> },
                //     ],
                // },
                // {
                //     path: 'pages',
                //     children: [
                //         {
                //             path: 'blog',
                //             children: [
                //                 { path: '', element: <LoadComponent component={Blog} /> },
                //                 { path: 'post', element: <LoadComponent component={BlogPost} /> },
                //             ],
                //         },
                //         { path: 'company', element: <LoadComponent component={Company} /> },
                //         { path: 'contact', element: <LoadComponent component={Contact} /> },
                //         { path: 'career', element: <LoadComponent component={Career} /> },
                //         { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                //         {
                //             path: 'portfolio',
                //             children: [
                //                 { path: 'grid', element: <LoadComponent component={PortfolioGrid} /> },
                //                 { path: 'masonry', element: <LoadComponent component={PortfolioMasonry} /> },
                //                 { path: 'item', element: <LoadComponent component={PortfolioItem} /> },
                //             ],
                //         },
                //         { path: 'help', element: <LoadComponent component={HelpDesk} /> },
                //     ]
                // },
            ],
        },
        {
            // protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} />,
            children: [
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'account',
                            children: [
                                { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
                                { path: 'settings', element: <LoadComponent component={Settings} /> },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export default AllRoutes;
